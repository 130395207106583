<template>
	<header class="hero">
		<nav class="nav">
			<div>
				<img src="@/assets/images/logo.svg" class="logo">
			</div>
			<div class="navigation">
				<a href="https://app.carriercrow.com">
					Login
				</a>
				<a href="https://app.carriercrow.com/sign-up" class="btn outline ml-3">
					Sign Up
				</a>
			</div>
		</nav>
		<div class="copy">
			<div class="heading">
				Successful email is yours
			</div>
			<div class="subheading my-4">
				Proven to grow your engaging audience with a user-friendly marketing platform
			</div>
			<div>
				<a href="https://app.carriercrow.com/sign-up" class="btn">
					Create A Free Account
				</a>
			</div>
		</div>
	</header>

	<section class="introduction">
		<figure class="screen-montage">
			<img class="desktop" src="@/assets/images/large-desktop.png" data-aos="fade-right">
			<img class="mobile" src="@/assets/images/large-mobile.png" data-aos="fade-left">
		</figure>

		<div class="content-heading">
			<header data-aos="fade-left">
				<div class="h4 mb-2">
					How it works
				</div>
				<div class="h3">
					The easiest way to interact with your audience all in one place.
				</div>
			</header>
			<div class="grid" data-aos="fade-right">
				<div class="item">
					<div>
						<svg class="feather">
							<use xlink:href="@/assets/images/feather-sprite.svg#users" />
						</svg>
						<div class="heading mt-2 mb-3">
							Manage subscribers
						</div>
						<div class="p text-muted">
							Easy to manage lists from all your sources with efficient audience segmentation based on behavior so you can target the right contact.
						</div>
					</div>
				</div>
				<div class="item">
					<div>
						<svg class="feather">
							<use xlink:href="@/assets/images/feather-sprite.svg#layers" />
						</svg>
						<div class="heading mt-2 mb-3">
							Build templates
						</div>
						<div class="p text-muted">
							Create content that is uniquely yours with our easy-to-use template builder. 
						</div>
					</div>
				</div>
				<div class="item">
					<div>
						<svg class="feather">
							<use xlink:href="@/assets/images/feather-sprite.svg#calendar" />
						</svg>
						<div class="heading mt-2 mb-3">
							Schedule campaigns
						</div>
						<div class="p text-muted">
							Plan and schedule campaigns ahead of time from desktop or mobile
						</div>
					</div>
				</div>
				<div class="item">
					<div>
						<svg class="feather">
							<use xlink:href="@/assets/images/feather-sprite.svg#thumbs-up" />
						</svg>
						<div class="heading mt-2 mb-3">
							Affordable plans
						</div>
						<div class="p text-muted">
							We’ve got something to prove & we're not greedy like the rest passing the savings on to you.
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="highlights">
		<div class="badge">
			<img src="@/assets/images/icon.svg">
		</div>
		<div class="container">
			<div class="item">
				<figure data-aos="fade-left">
					<img src="@/assets/images/screenshots/segments-screenshot.png">
					<div class="fab">
						<div class="list">
							<h3>Features</h3>
							<ul>
								<li>
									Template Creator
								</li>
								<li>
									Code Editor
								</li>
								<li>
									Live Preview
								</li>
							</ul>
						</div>
						<div class="button">
							<div class="wrapper">
								<div class="line half first"></div>
								<div class="line"></div>
								<div class="line half last"></div>
							</div>
						</div>
					</div>
				</figure>
				<div class="copy">
					<h3 class="mt-4 mt-lg-0 mb-3">
						We'll help you reach your target audience
					</h3>
					<p class="text-muted">
						Get to know your user list and mail them at the right time with our campaign builder.
					</p>
				</div>
			</div>
			<div class="item">
				<figure data-aos="fade-right">
					<img src="@/assets/images/screenshots/content-screenshot.png">
					<div class="fab">
						<div class="list">
							<h3>Features</h3>
							<ul>
								<li>
									Template Creator
								</li>
								<li>
									Code Editor
								</li>
								<li>
									Live Preview
								</li>
							</ul>
						</div>
						<div class="button">
							<div class="wrapper">
								<div class="line half first"></div>
								<div class="line"></div>
								<div class="line half last"></div>
							</div>
						</div>
					</div>
				</figure>
				<div class="copy">
					<h3 class="mt-4 mt-lg-0 mb-3">
						Content designed by you
					</h3>
					<p class="text-muted">
						Choose from proven designed layouts or create something entirely yours with the Carrier Crow Template Builder.
					</p>
				</div>
			</div>
			<div class="item">
				<figure data-aos="fade-left">
					<img src="@/assets/images/screenshots/triggers-screenshot.png">
					<div class="fab">
						<div class="list">
							<h3>Features</h3>
							<ul>
								<li>
									Template Creator
								</li>
								<li>
									Code Editor
								</li>
								<li>
									Live Preview
								</li>
							</ul>
						</div>
						<div class="button">
							<div class="wrapper">
								<div class="line half first"></div>
								<div class="line"></div>
								<div class="line half last"></div>
							</div>
						</div>
					</div>
				</figure>
				<div class="copy">
					<h3 class="mt-4 mt-lg-0 mb-3">
						Email journeys that flow
					</h3>
					<p class="text-muted">
						Automate as much of your email campaigns as you need so that you can spend more time focusing on what's important.
					</p>
				</div>
			</div>
		</div>
	</section>

	<div class="content-heading metrics">
		<header data-aos="fade-left">
			<div class="h4 mb-3">
				How we stack up
			</div>
			<div class="h3">
				A quick look at the numbers.
			</div>
		</header>
		<div class="grid" data-aos="fade-right">
			<div class="item">
				<div>
					<div class="h3">
						50k+
					</div>
					<div class="heading text-muted mb-3">
						Daily Subscribers
					</div>
					<div class="p text-muted">
						We've built a system that can scale as large as your business needs.
					</div>
				</div>
			</div>
			<div class="item">
				<div>
					<div class="h3">
						1.5m+
					</div>
					<div class="heading text-muted mb-3">
						Emails Sent Daily
					</div>
					<div class="p text-muted">
						Realizing the people behind the numbers before sending.
					</div>
				</div>
			</div>
			<div class="item">
				<div>
					<div class="h3">
						500k+
					</div>
					<div class="heading text-muted mb-3">
						Users Engaged
					</div>
					<div class="p text-muted">
						Enough to power any successful brand.
					</div>
				</div>
			</div>
			<div class="item">
				<div>
					<div class="h3">
						Up to 200%
					</div>
					<div class="heading text-muted mb-3">
						Realized ROI
					</div>
					<div class="p text-muted">
						There's no limit to the success you can create with elegant email.
					</div>
				</div>
			</div>
		</div>
	</div>

	<section class="cta">
		<div class="content-heading">
			<header data-aos="fade-left">
				<div class="h4 mb-3">
					Are you ready to take your email marketing to the next step?
				</div>
				<div class="h3">
					Ready to get started? Get in touch or create an account.
				</div>
				<p class="text-muted mt-3 mb-4">
					
				</p>
				<div>
					<a href="mailto:team@frozencrow.com" class="btn white">
						Contact Us
					</a>
					<a href="https://app.carriercrow.com/sign-up" class="btn white outline ml-3" style="color: #fff;">
						Create A Free Account
					</a>
				</div>
			</header>
		</div>
	</section>

	<footer class="footer">
		<div class="logo">
			<img src="@/assets/images/icon.svg" width="40">
		</div>
	<!--	<ul class="m-0 p-0">
			<li>
				<a href="#">
					Privacy Policy
				</a>
			</li>
			<li>
				<a href="#">
					Terms of Use
				</a>
			</li>
			<li>
				<a href="#">
					Cookie Policy
				</a>
			</li>
			<li>
				<a href="#">
					CCPA
				</a>
			</li>
			<li>
				<a href="#">
					Contact Us
				</a>
			</li>
		</ul> -->
		<div class="text-muted">
			© FrozenCrow, Inc.
		</div>
	</footer>
</template>
<!--<script src="@/assets/js/app.js"></script>-->

<script>
import AOS from "aos";
export default {
	name: 'App',
	mounted () {
		AOS.init();
	}
}
</script>

<style lang="scss">
@import "@/assets/css/scss/style.scss";
</style>
